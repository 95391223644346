import { createContext } from 'react';

export interface LoadContextInterface {
	reload: CallableFunction | null;
	// setValue: ?CallableFunction;
	loading: boolean;
}

const defaultValue: LoadContextInterface = {
	reload: null,
	// setValue: null,
	loading: false,
};
export const LoadContext = createContext<LoadContextInterface>(defaultValue);
