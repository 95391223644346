// modules
import React from 'react';
import { Header, Container, Icon } from 'semantic-ui-react';

const DefaultPage = () => {
	return (
		<Container className="home">
			<Header as="h1">
				<a
					href={process.env.REACT_APP_PLATFORM_URL}
					title="Retourner sur MY.Facturation"
				>
					<Icon link name="arrow circle left" color="blue" />
					<span className="sr-only">Retourner sur MY.Faturation</span>
				</a>{' '}
				Bienvenue sur MY.Compta.
			</Header>
			<p>
				Vous devez être connecté depuis un autre logiciel pour accèder à vos
				données comptables.
			</p>
		</Container>
	);
};
export default DefaultPage;
