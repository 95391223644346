import React from 'react';
import { useGoBack, useQuery } from '@dometech-group/dometech-packages';
import { Icon } from 'semantic-ui-react';

const GoBackButton = ({ goBackRoute }) => {
	const goBack = useGoBack();
	const { previous } = useQuery();
	return (
		<Icon
			link
			name="arrow circle left"
			title="Revenir en arrière"
			color="blue"
			onClick={e => goBack(previous)}
		/>
	);
};

export default GoBackButton;
