import { createSlice } from '@reduxjs/toolkit';

const exercisesSlice = createSlice({
	name: 'exercises',
	initialState: [],
	reducers: {
		setExercises: (state, { payload: exercises }) => exercises,
		removeExercises: () => [],
	},
});

export const { setExercises, removeExercises } = exercisesSlice.actions;

export default exercisesSlice.reducer;
