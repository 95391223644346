import { useState } from 'react';

const useModal = () => {
	const [open, setOpen] = useState(false);

	const handleOpen = e => {
		e?.preventDefault();
		setOpen(true);
	};
	const handleClose = e => {
		e?.preventDefault();
		setOpen(false);
	};

	return [open, handleOpen, handleClose];
};

export default useModal;
