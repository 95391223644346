import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
	name: 'data',
	initialState: {},
	reducers: {
		setData: {
			reducer: (state, { payload: { key, data } }) => {
				state[key] = data;
			},
			prepare: (key, data) => ({ payload: { key, data } }),
		},
		removeData: {
			reducer: () => ({}),
		},
	},
});

export const { setData, removeData } = dataSlice.actions;

export default dataSlice.reducer;
