import { useNavigate, useLocation } from 'react-router-dom';

const useCtrlClick = ({ to, handleClick, listen = true }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const handleCtrlClick = e => {
		if (!listen) return;

		const { target, currentTarget } = e;
		if (
			target !== currentTarget &&
			(target.localName === 'a' ||
				target.localName === 'label' ||
				target.localName === 'button' ||
				target.classList.contains('link') ||
				target.classList.contains('clickable'))
		) {
			return;
		}

		if (e.ctrlKey) {
			window.open(`${to}?previous=${pathname}`, '_blank');
		} else if (e.shiftKey) {
			window.open(`${to}?previous=${pathname}`);
		} else if (handleClick) {
			handleClick(e);
		} else {
			navigate(to);
		}
	};

	return handleCtrlClick;
};
export default useCtrlClick;
