import { useEffect, useRef, useState } from 'react';

const useDragModal = id => {
	const [isDragging, setIsDragging] = useState(false);
	const ref = useRef(null);
	const offsetRef = useRef({ x: 0, y: 0 });

	useEffect(() => {
		if (isDragging) {
			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('mouseup', handleMouseUp);
		} else {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
		}

		return () => {
			if (isDragging) {
				document.removeEventListener('mousemove', handleMouseMove);
				document.removeEventListener('mouseup', handleMouseUp);
			}
		};
	}, [isDragging]);

	const handleMouseMove = e => {
		if (isDragging && ref.current) {
			const nextX = e.clientX - offsetRef.current.x;
			const nextY = e.clientY - offsetRef.current.y;
			// Constrain the modal within the window boundaries
			const maxX = window.innerWidth - ref.current.offsetWidth;
			const maxY = window.innerHeight - ref.current.offsetHeight;

			ref.current.style.transform = 'none';
			ref.current.style.left = Math.max(0, Math.min(nextX, maxX)) + 'px';
			ref.current.style.top = Math.max(0, Math.min(nextY, maxY)) + 'px';
		}
	};

	const handleMouseUp = () => {
		setIsDragging(false);
	};

	const handleMouseDown = e => {
		if (
			!e.target.classList.contains('grabble') &&
			!e.target.classList.contains('sub-grabble')
		) {
			return;
		}

		e.stopPropagation();
		e.preventDefault();
		e.cancelBubble = true;
		e.returnValue = false;
		handleZindex();

		setIsDragging(true);

		ref.current = document.getElementById(id);
		if (ref.current) {
			const rect = ref.current.getBoundingClientRect();
			offsetRef.current = {
				x: e.clientX - rect.left,
				y: e.clientY - rect.top,
			};
		}
	};

	const handleZindex = () => {
		const modals = document.getElementsByClassName('ui modal portaled');
		for (let modal of modals) {
			if (modal.id === id) {
				modal.style.zIndex = 998;
			} else {
				modal.style.zIndex = 997;
			}
		}
	};

	return { isDragging, handleMouseDown, handleZindex };
};

export default useDragModal;
