import { createSlice } from '@reduxjs/toolkit';

const portalsSlice = createSlice({
	name: 'portal',
	initialState: [],
	reducers: {
		addPortal: (state, action) => {
			state.push(action.payload);
		},
		removePortal: (state, action) => {
			return state.filter(
				id => id !== action.payload && document.getElementById(id) // just in case when some modals have missed deletion
			);
		},
	},
});

export const { addPortal, removePortal } = portalsSlice.actions;

export default portalsSlice.reducer;
