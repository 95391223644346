// modules
import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormInput, Label, Segment } from 'semantic-ui-react';
import useGet from '../hooks/fetch/actions/useGet';
import { defaultArray, makeClassName } from '@dometech-group/dometech-packages';
import { setData } from '../reducers/dataReducer';
import SelectField from './SelectField';

const journalToOptions = (journals, all = false) =>
	journals.reduce(
		(dict, journal) => {
			dict[journal.id] = journal.code + ' - ' + journal.label;
			return dict;
		},
		all ? { 0: 'Tous' } : { 0: ' ' }
	);

const JournalSelector = memo(
	({
		value,
		isFilter = false,
		onChange,
		label = 'Journaux',
		className,
		error,
		required = false,
	}) => {
		const dispatch = useDispatch();
		const data = useSelector(state => state.data);

		const { value: journals, loading } = useGet({
			key: 'journals',
			defaultValue: data.journals || defaultArray,
			stored: !!data.journals,
			url: 'journals',
			callback: j => {
				dispatch(setData('journals', j));
				return j;
			},
		});

		const options = useMemo(
			() => journalToOptions(journals, isFilter),
			[journals, isFilter]
		);

		if (loading) {
			return (
				<Segment basic loading>
					<FormInput label={label} />
				</Segment>
			);
		}
		if (!Object.keys(journals).length) return null;
		return (
			<SelectField
				label={isFilter ? null : label}
				name="journal"
				value={value}
				options={options}
				onChange={onChange}
				orderByKey
				className={makeClassName(isFilter && 'labeled', className)}
				error={error ? (typeof error === 'string' ? error : error.code) : null}
				required={required}
			>
				{isFilter && <Label basic>{label}</Label>}
			</SelectField>
		);
	}
);
export default JournalSelector;
