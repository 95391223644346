import { useEffect, useState } from 'react';
import useLocalFetch from '../useLocalFetch';

const useGet = ({ key, defaultValue, url, callback, extraResult, stored }) => {
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		if (!stored) {
			reload();
		}
	}, [url]);

	const reload = () => load({ url });

	const [load, loading] = useLocalFetch({
		handleResult: result => {
			if (result?.success) {
				setValue(callback ? callback(result[key]) : result[key]);
				if (extraResult) extraResult(result);
			}
		},
	});

	return { value, loading, setValue, reload };
};
export default useGet;
