// modules
import React from 'react';
import { useSelector } from 'react-redux';
import { Divider, Menu, Sidebar, SidebarPushable } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import BrandLink from '../menus/BrandLink';
// components
// import MenuEnterprise from '../menus/MenuEnterprise';
// import MenuUser from '../menus/MenuUser';

const Aside = () => {
	const user = useSelector(state => state.user);
	return (
		<SidebarPushable as="aside" id="aside">
			<Sidebar animation="overlay" id="sidebar">
				<BrandLink />
				{/* {!!user.id && (
					<>
						<div className="grow">
							<MenuEnterprise className="ui vertical" />
						</div>
						<Menu vertical>
							<Divider />
							<NavLink end className="item" to="/enterprises">
								<EnterprisesSVG /> <span>Mes entreprises</span>
							</NavLink>
							<MenuItemNote />
							<Divider />
							<MenuUser />
						</Menu>
					</>
				)} */}
			</Sidebar>
		</SidebarPushable>
	);
};

export default Aside;
