import React from 'react';
import { makeClassName, ErrorBoundary } from '@dometech-group/dometech-packages';
import {
	FlashMessages,
	useDarkMode,
	useTokenManager,
} from '@dometech-group/dometech-packages-redux';
// import { FlashMessages, useDarkMode, useTokenManager } from './hooks/dometech-packages-redux';
import { Header } from 'semantic-ui-react';
import { MESSAGE_LOADER, useInitLocalRequest } from './hooks/fetch/useLocalFetch';
import Aside from './layout/Aside';
import Footer from './layout/Footer';
import PageRouter from './routers/PageRouter';
import PageError from './pages/PageError';

function App() {
	const [darkMode] = useDarkMode();
	const initRequest = useInitLocalRequest();
	useTokenManager({
		redirection: null,
		escapeRegex: /^\/(token|client-id).*$/,
		params: initRequest(),
		loadingIcon: MESSAGE_LOADER,
	});

	return (
		<div className={makeClassName('app', darkMode && 'dark-mode')}>
			<Header />
			<Aside />
			<main className="side">
				<ErrorBoundary Component={PageError}>
					<FlashMessages className="ui message" />
					<PageRouter />
				</ErrorBoundary>
			</main>
			<Footer />
		</div>
	);
}

export default App;
