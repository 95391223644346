import { TextAreaAutoRows } from '@dometech-group/dometech-packages-ui-semantic';
import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

// id is mandatory
const AddComment = props => {
	const [adding, setAdding] = useState(false);
	if (!adding) {
		return (
			<Button
				basic
				primary
				size="tiny"
				icon="plus"
				content="Ajouter un commentaire"
				onClick={() => setAdding(true)}
			/>
		);
	}
	return <TextAreaAutoRows name="comment" label="Commentaire" {...props} />;
};

export default AddComment;
