import { formatPrices } from '@dometech-group/dometech-packages';

// export const envImport = module =>
// 	process.env.NODE_ENV === 'production'
// 		? require(`@dometech-group/${module}`)
// 		: require(`../custom_modules/${module}/src/`);

export const nullablePrice = price => (!price ? null : formatPrices(price));

export const strToPlrs = str =>
	str.endsWith('y') ? str.slice(0, -1) + 'ies' : str + 's';

export const stringEnum = (...args) =>
	args.reduce((acc, str) => (str ? acc + ' - ' + str : acc), '');
