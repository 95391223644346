import { createSlice } from '@reduxjs/toolkit';

const accountingSlice = createSlice({
	name: 'accounting',
	initialState: { loading: true },
	reducers: {
		setAccounting: (state, { payload: accounting }) => accounting,
		updateAccounting: (state, { payload: accounting }) => ({
			...state,
			...accounting,
		}),
		removeAccounting: () => ({}),
		setAccountingValue: {
			reducer: (state, { payload: { name, value } }) => {
				state[name] = value;
			},
			prepare: (name, value) => ({ payload: { name, value } }),
		},
	},
});

export const { setAccounting, updateAccounting, removeAccounting, setAccountingValue } =
	accountingSlice.actions;

export default accountingSlice.reducer;
