import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useLocalFetch from '../useLocalFetch';
import { defaultObject, POST, PUT } from '@dometech-group/dometech-packages';
import { strToPlrs } from '../../../functions';
// import { envImport } from '../functions';
// const { buildFieldsMessage } = envImport('dometech-packages');
const { buildFieldsMessage } = require('@dometech-group/dometech-packages');

function useCreate({ name, callback }) {
	const accounting = useSelector(state => state.accounting);
	const [error, setError] = useState(defaultObject);
	const namePlur = useMemo(() => strToPlrs(name), [name]);

	const [load, loading] = useLocalFetch({
		handleResult: result => {
			if (result) {
				if (result.success) {
					if (error) setError(defaultObject);
					if (callback) callback(result);
				} else if (result.errors) {
					setError(buildFieldsMessage(result.errors));
				}
			}
		},
	});

	const handleSubmit = body => {
		console.log('load create');
		load({
			url:
				`accountings/${accounting.id}/${namePlur}` +
				(body.id ? `/${body.id}` : ''),
			method: body.id ? PUT : POST,
			body,
		});
	};

	return {
		loading,
		error,
		setError,
		handleSubmit,
	};
}

export default useCreate;
