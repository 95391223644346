import React, { useState } from 'react';
import { Button, Icon, Modal, Portal } from 'semantic-ui-react';
import useDragModal from '../hooks/modal/useDragModal';
import { makeClassName } from '@dometech-group/dometech-packages';
import { useDispatch, useSelector } from 'react-redux';
import { addPortal, removePortal } from '../reducers/portalsReducer';

const DraggableModal = ({
	id,
	title,
	content,
	children,
	footer,
	className,
	open,
	handleOpen,
	handleClose,
	...props
}) => {
	const dispatch = useDispatch();
	const portals = useSelector(state => state.portals);
	const { isDragging, handleMouseDown, handleZindex } = useDragModal(id);
	const [minimized, setMinimized] = useState(false);
	const [tabAnimated, setTabAnimated] = useState(false);

	const onClose = e => {
		handleClose(e);
		dispatch(removePortal(id));
	};

	const handleMinimize = e => {
		e.preventDefault();
		e.stopPropagation();
		setMinimized(!minimized);
		if (minimized) {
			dispatch(removePortal(id));
			handleZindex();
			setTabAnimated(false); // remove transition at soon as possible
		} else {
			dispatch(addPortal(id));
			setTimeout(() => {
				setTabAnimated(true);
			}, 300); // add transition after a time, to avoid transition on minimizing
		}
	};

	const index = portals.indexOf(id);
	return (
		<Portal
			open={open}
			onClose={onClose}
			onOpen={handleOpen}
			// portal close conditions :
			closeOnTriggerBlur={false}
			closeOnTriggerClick={false}
			closeOnDocumentClick={false}
			closeOnPortalMouseLeave={false}
			closeOnTriggerMouseLeave={false}
			closeOnEscape={true}
		>
			<div
				className={makeClassName(
					'ui modal portaled',
					minimized ? 'minimized' : 'grabble',
					tabAnimated && 'tab-animated',
					isDragging && 'grabbing',
					props.size,
					className
				)}
				id={id}
				data-portal-index={index}
				data-left={(index % 4) * 25}
				data-bottom={Math.floor(index / 4) * 4.64}
				onMouseDown={handleMouseDown}
				{...props}
			>
				<Modal.Header className="flex sub-grabble align-items-start justify-content-between">
					<div className="flex sub-grabble align-items-start">
						<Icon name="arrows alternate" className="sub-grabble" />
						<h2 className="text-overflow mt-0 ml-0_5 sub-grabble">{title}</h2>
					</div>
					<div className='sub-grabble white-space-nowrap'>
						<Button
							icon={'window ' + (minimized ? 'maximize' : 'minimize')}
							onClick={handleMinimize}
							className="mr-0 ml-1 p-0_5"
						/>
						<Button
							icon="close"
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								onClose(e);
							}}
							className="mr-0 ml-0_5 p-0_5"
						/>
					</div>
				</Modal.Header>
				<Modal.Content className="sub-grabble">
					{children || content}
				</Modal.Content>
				{!!footer && (
					<Modal.Footer className="sub-grabble">{footer}</Modal.Footer>
				)}
			</div>
		</Portal>
	);
};

export default DraggableModal;
