// modules
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Header, Container, Icon, Label, Loader } from 'semantic-ui-react';
import useLocalFetch from '../hooks/fetch/useLocalFetch';

const AccountingsPage = () => {
	const [accountings, setAccountings] = useState([]);

	useEffect(() => {
		load({ url: 'accountings' });
	}, []);

	const [load, loading] = useLocalFetch({
		handleResult: result => result?.success && setAccountings(result.accountings),
	});

	return (
		<Container className="home">
			<Header as="h1">
				<a
					href={process.env.REACT_APP_PLATFORM_URL}
					title="Retourner sur MY.Facturation"
				>
					<Icon link name="arrow circle left" color="blue" />
					<span className="sr-only">Retourner sur MY.Faturation</span>
				</a>{' '}
				Bienvenue sur MY.Compta.
			</Header>
			<div className="flex-grid">
				{loading ? (
					<Loader active inline="centered" />
				) : (
					accountings.map(accounting => (
						<Label
							key={accounting.id}
							color="blue"
							size="big"
							className="position-relative"
						>
							<Link
								to={'/accountings/' + accounting.id}
								className="extended"
							>
								{accounting.label}
							</Link>
						</Label>
					))
				)}
			</div>
		</Container>
	);
};
export default AccountingsPage;
