// modules
import React from 'react';
import { Button, Container } from 'semantic-ui-react';
import { useGoBack } from '@dometech-group/dometech-packages';
// components
import PageLayout from '../layout/PageLayout';

const Page404 = () => {
	const goBack = useGoBack();

	return (
		<PageLayout title="Erreur 404">
			<p>La page que vous recherchez n'existe pas.</p>
			<Button
				color="orange"
				onClick={() => goBack()}
				icon="left arrow"
				content="Revenir en arrière"
			/>
		</PageLayout>
	);
};
export default Page404;
