import React from 'react';
import { Link } from 'react-router-dom';
import { makeClassName } from '@dometech-group/dometech-packages';
import { Image } from 'semantic-ui-react';

const BrandLink = React.memo(({ className }) => (
	<div className={makeClassName('brand', 'relative-parent', className)}>
		<Image src="/logo.svg" alt="Logo de MY.Facturation" />
		<Image src="/small-logo.svg" alt="Logo de MY.Facturation" className="small" />
		<Link to="/" className="extended">
			<span className="sr-only">MY.Compta</span>
		</Link>
	</div>
));

export default BrandLink;
