// modules
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from '@dometech-group/dometech-packages';
import { addMessage, setUser } from '@dometech-group/dometech-packages-redux';
import { Container, Header, Loader } from 'semantic-ui-react';
import useLocalFetch from '../hooks/fetch/useLocalFetch';

const LoggingPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { clientId, token, refreshToken, accountingExternalId } = useParams();
	const { setItemToStorage } = useLocalStorage();

	useEffect(() => {
		if (token) {
			setItemToStorage(process.env.REACT_APP_LOCAL_KEY, { refreshToken });
			loadUser({
				url: 'user',
				token,
				headers: {
					'Client-Id': clientId,
					// 'Client-Secret': process.env.REACT_APP_CLIENT_SECRET,
				},
			});
		}
	}, []);

	const [loadUser] = useLocalFetch({
		handleResult: result => {
			if (result) {
				if (result.success) {
					dispatch(addMessage({ value: 'Connecté', success: true }));
					result.user.token = token;
					result.user.refreshToken = refreshToken;
					result.user.clientId = clientId;
					dispatch(setUser(result.user));
					setItemToStorage(process.env.REACT_APP_LOCAL_KEY, result.user);
					if (accountingExternalId) {
						loadAccounting({
							url: 'accountings/by-platform/' + accountingExternalId,
							token,
							headers: {
								'Client-Id': clientId,
								// 'Client-Secret': process.env.REACT_APP_CLIENT_SECRET,
							},
						});
					} else {
						navigate('/', { replace: true });
					}
				} else {
					dispatch(addMessage({ value: 'Echec de la connexion', error: true }));
					navigate('/', { replace: true });
				}
			}
		},
	});

	const [loadAccounting] = useLocalFetch({
		handleResult: result => {
			if (result?.success) {
				navigate(`/accountings/${result.accounting.id}`, { replace: true });
			} else {
				navigate('/', { replace: true });
			}
		},
	});

	return (
		<Container>
			<Header className="flex" as="h1">
				Connexion en cours
			</Header>
			<Loader active inline="centered" />
		</Container>
	);
};
export default LoggingPage;
