import { configureStore } from '@reduxjs/toolkit';
import {
	userReducer,
	messagesReducer,
	darkModeReducer,
	queriesReducer,
} from '@dometech-group/dometech-packages-redux';
import accountingReducer from './reducers/accountingReducer';
import dataReducer from './reducers/dataReducer';
import portalsReducer from './reducers/portalsReducer';
import exercisesReducer from './reducers/exercisesReducer';

const store = configureStore({
	reducer: {
		user: userReducer,
		accounting: accountingReducer,
		exercises: exercisesReducer,
		messages: messagesReducer,
		darkMode: darkModeReducer,
		queries: queriesReducer,
		data: dataReducer,
		portals: portalsReducer,
	},
});
export default store;
