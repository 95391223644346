// modules
import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
// import useSubMenu from '../../hooks/useSubMenu';
// import MenuEnterprise from '../menus/MenuEnterprise';
// components

function Footer() {
	// const subMenu = useSubMenu();
	// const enterprise = useSelector(state => state.enterprise);

	return (
		<footer id="footer">
			<Menu className="footer-menu justify-content-center">
				<a className="item" href={process.env.REACT_APP_PLATFORM_URL + 'support'}>
					Support
				</a>
				<a className="item" href={process.env.REACT_APP_PLATFORM_URL + 'faq'}>
					FAQ
				</a>
				<a className="item" href={process.env.REACT_APP_PLATFORM_URL + 'cgu'}>
					CGU
				</a>
				<a className="item" href={process.env.REACT_APP_PLATFORM_URL + 'cgv'}>
					CGV
				</a>
				<a
					className="item"
					href={process.env.REACT_APP_PLATFORM_URL + 'legal-notice'}
				>
					Mentions Légales
				</a>
				{/* {!!enterprise.id && enterprise.currentUser.super && (
					<NavLink
						className="item"
						to={`/enterprises/${enterprise.id}/formulas`}
					>
						Achats Packs
					</NavLink>
				)} */}
				<a
					className="item"
					href="https://www.linkedin.com/company/my-facturation/"
					target="_blank"
					rel="nofollow noreferrer"
					title="LinkedIn"
				>
					<span className="sr-only">LinkedIn</span>
					<Icon name="linkedin" size="large" className="mr-0" />
				</a>
				<a
					className="item"
					href="https://www.facebook.com/MY.Facturation/"
					target="_blank"
					rel="nofollow noreferrer"
					title="Facebook"
				>
					<span className="sr-only">Facebook</span>
					<Icon name="facebook" size="large" className="mr-0" />
				</a>
				<a
					className="item"
					href="https://twitter.com/MYFacturation"
					target="_blank"
					rel="nofollow noreferrer"
					title="Twitter"
				>
					<span className="sr-only">Twitter</span>
					<Icon name="twitter" size="large" className="mr-0" />
				</a>
			</Menu>
			{/* {!!enterprise.id && (
				<Menu
					className={makeClassName('footer-responsive', subMenu && 'sub-menu')}
				>
					<MenuEnterprise className="menu-bottom" mobile />
				</Menu>
			)} */}
		</footer>
	);
}
export default Footer;
