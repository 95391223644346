// modules
import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import GoBackButton from '../buttons/GoBackButton';
import MenuAccounting from '../menus/MenuAccounting';

const PageLayout = ({ title, goBack = true, className, children, withMenu = false }) => {
	return (
		<>
			<Container className={className}>
				<Header className="flex" as="h1">
					{goBack && (
						<div>
							<GoBackButton />
						</div>
					)}
					{title}
				</Header>
				{withMenu && <MenuAccounting />}
				{children}
			</Container>
		</>
	);
};
export default PageLayout;
